import { FETCH_HOMEPAGE_FEATURES_SUCCESS } from "../actions/homepageFeatureActions";

const initState = {
  homepageFeatures: []
};

const homepageFeaturesReducer = (state = initState, action) => {
	console.log({homepageFeaturesReducer:action.type})
  if (action.type === FETCH_HOMEPAGE_FEATURES_SUCCESS) {
    return {
      ...state,
      homepageFeatures: action.payload
    };
  }

  return state;
};

export default homepageFeaturesReducer;
