export const ACCOUNT_INITIALIZE = "ACCOUNT_INITIALIZE";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";


// //add to cart
// export const loginSuccess = (
//   id,
//   email,
//   token,
//   loginToast
// ) => {
//   return dispatch => {
//     if (addToast) {
//       loginToast("Logged In", { appearance: "success", autoDismiss: true });
//     }
//     dispatch({
//       type: ACCOUNT_INITIALIZE,
//       payload: {
//         id,
//         email,
//         token
//       }
//     });
//   };
// };