import { FETCH_CUSTOMITEMS_SUCCESS } from "../actions/customItemsActions";

const initState = {
  customItems: []
};

const customItemsReducer = (state = initState, action) => {
	console.log({customItemsReducer:action.type})
  if (action.type === FETCH_CUSTOMITEMS_SUCCESS) {
    return {
      ...state,
      customItems: action.payload
    };
  }

  return state;
};

export default customItemsReducer;
