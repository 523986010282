export const FETCH_HOMEPAGE_FEATURES_SUCCESS = "FETCH_HOMEPAGE_FEATURES_SUCCESS";

const fetchHomepageFeaturesSuccess = homepageFeatures => ({
    type: FETCH_HOMEPAGE_FEATURES_SUCCESS,
    payload: homepageFeatures
});

// fetch products
export const fetchHomepageFeatures = (homepageFeatures) => {
	console.log("Fetching HomepageFeatures")
    return dispatch => {
        dispatch(fetchHomepageFeaturesSuccess(homepageFeatures));
    };
};