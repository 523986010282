import { FETCH_CUSTOMERSTORIES_SUCCESS } from "../actions/customerStories";

const initState = {
  customerStories: []
};

const customerStoriesReducer = (state = initState, action) => {
	console.log({customerStoriesReducer:action.type})
  if (action.type === FETCH_CUSTOMERSTORIES_SUCCESS) {
    return {
      ...state,
      customerStories: action.payload
    };
  }

  return state;
};

export default customerStoriesReducer;
