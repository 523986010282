export const FETCH_CUSTOMERSTORIES_SUCCESS = "FETCH_CUSTOMERSTORIES_SUCCESS";

const fetchCustomerStoriesSuccess = customerStories => ({
    type: FETCH_CUSTOMERSTORIES_SUCCESS,
    payload: customerStories
});

// fetch products
export const fetchCustomerStories = (customerStories) => {
	console.log("Fetching CustomerStories")
    return dispatch => {
        dispatch(fetchCustomerStoriesSuccess(customerStories));
    };
};