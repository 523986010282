export const FETCH_CUSTOMITEMS_SUCCESS = "FETCH_CUSTOMITEMS_SUCCESS";

const fetchCustomItemsSuccess = customItems => ({
    type: FETCH_CUSTOMITEMS_SUCCESS,
    payload: customItems
});

// fetch products
export const fetchCustomItems = (customItems) => {
	console.log("Fetching CustomItems")
    return dispatch => {
        dispatch(fetchCustomItemsSuccess(customItems));
    };
};